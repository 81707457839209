import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/Blog/graphql-error-list";
import PortableText from "../components/Blog/portableText";
import { GatsbyImage } from "gatsby-plugin-image";
import AniLink from "gatsby-plugin-transition-link/AniLink";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Industries from "../components/Repeating/Industries";
import Testimonials from "../components/Repeating/Testimonials";
import FindYourCPA from "../components/Repeating/FindYourCPA";
import Locations from "../components/Repeating/Locations";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";

export const query = graphql`
  query SubServiceTemplateQuery($id: String!) {
    page: sanitySubService(id: { eq: $id }) {
      seoTitle
      metaDescription
      openGraphImage {
        asset {
          url
        }
      }
      twitterCardImage {
        asset {
          url
        }
      }
      heroHeading
      introHeading
      _rawIntroText(resolveReferences: { maxDepth: 5 })
      ourProcessHeading
      steps {
        heading
        _rawText(resolveReferences: { maxDepth: 5 })
      }
      sidebar {
        name
        sidebarHeading
        services {
          title
          slug {
            current
          }
        }
      }
    }
    grayCrosses: file(relativePath: { eq: "blog/graycrosses.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

const SubServiceTemplate = (props) => {
  const { data, errors } = props;
  const page = data && data.page;

  let slug = "";
  if (typeof window !== "undefined") {
    slug = window.location.pathname;
  }

  return (
    <Layout ctaLink="/business-pricing/">
      {errors && <SearchEngineOptimization title="GraphQL Error" />}
      {page && (
        <SearchEngineOptimization
          title={page.seoTitle}
          description={page.metaDescription}
          openGraphImage={page.openGraphImage && page.openGraphImage.asset.url}
          twitterOpenGraphImage={
            page.twitterCardImage && page.twitterCardImage.asset.url
          }
        />
      )}

      {errors && (
        <div className="container">
          <GraphQLErrorList errors={errors} />
        </div>
      )}

      {page && (
        <>
          <section className="relative overflow-hidden pt-16 pb-14 md:pt-28 md:pb-22">
            <div className="absolute top-0 left-0 h-full w-full bg-primary-900"></div>
            <div className="absolute top-0 left-0 h-full w-full">
              <GatsbyImage
                image={data.grayCrosses.childImageSharp.gatsbyImageData}
                className="h-full"
              />
            </div>
            <div className="container relative">
              <div className="mb-6 inline-block rounded-sm border-2 border-primary-500 px-2 text-sm font-medium uppercase text-primary-500">
                {page.sidebar.name}
              </div>
              <h1 className="mb-0 text-white">{page.heroHeading}</h1>
            </div>
          </section>

          <section className="relative pt-28 pb-20 md:pb-32">
            <div className="container">
              <div className="grid gap-y-10 md:grid-cols-12">
                <div className="md:col-span-8 md:col-start-1 md:pr-10 lg:pr-18">
                  <div className="mb-12 md:mb-16">
                    {page._rawIntroText && <h2>{page.introHeading}</h2>}
                    {page._rawIntroText && (
                      <PortableText blocks={page._rawIntroText} />
                    )}
                  </div>

                  <div>
                    {page.ourProcessHeading && (
                      <header className="mb-10">
                        <h3>{page.ourProcessHeading}</h3>
                      </header>
                    )}
                    {page.steps.length > 0 && (
                      <div className="relative">
                        <div className="absolute -left-12 top-0 h-[85%] w-2 border-l-2 border-dashed border-primary-800"></div>
                        {page.steps.map((item, i) => {
                          return (
                            <div key={i} className="relative mb-10">
                              <div className="absolute -left-18 -top-2 flex h-12 w-12 items-center justify-center rounded-full border border-primary-800 bg-primary-50 text-lg font-bold text-primary-800">
                                {i + 1}
                              </div>
                              <div className="mb-2.5 text-lg font-bold text-primary-900">
                                {item.heading}
                              </div>
                              <div>
                                <PortableText blocks={item._rawText} />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                    <ButtonSolid href="/business-pricing/" text="Get a Quote" />
                  </div>
                </div>

                <div className="md:col-span-4 md:col-end-13">
                  <div className="md:sticky md:top-32 md:ml-auto md:max-w-[336px]">
                    <div className="rounded-4xl bg-secondary-100 px-10 py-9">
                      {page.sidebar.sidebarHeading && (
                        <div className="mb-5 text-lg font-bold text-primary-900">
                          {page.sidebar.sidebarHeading}
                        </div>
                      )}
                      {page.sidebar.services && (
                        <ul className="space-y-2">
                          {page.sidebar.services.map((item, i) => {
                            return (
                              <li
                                key={i}
                                className="inline-flex w-full text-sm font-semibold"
                              >
                                <AniLink
                                  fade
                                  to={`/${item.slug.current}/`}
                                  className="relative flex items-center text-gray-700 no-underline hover:text-secondary-500"
                                >
                                  {slug === "/" + item.slug.current + "/" && (
                                    <div className="absolute -left-4 h-2 w-2 rounded-full bg-secondary-500"></div>
                                  )}
                                  {item.title}
                                </AniLink>
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Industries />
          <Testimonials />
          <FindYourCPA />
          <Locations />
          <CallToAction ctaLink="/business-pricing/" />
        </>
      )}
    </Layout>
  );
};

export default SubServiceTemplate;
